import React from 'react';

import Header from '../Views/Common/Header';
import Footer from '../Views/Common/Footer';

import ComingSoonCss from '../assets/css/ComingSoon.css';
import comingSoonImage from '../assets/img/comming_soon.png'; // Replace with your image path

const ComingSoon = () => {
    return (
        <>
            <div className='CommingSoonBody'>
                <Header />
                <div className="coming-soon-container" style={{marginTop:'45px'}}>
                    <img src={comingSoonImage} alt="Coming Soon" width={350} className="coming-soon-image" />
                    {/* <div className="coming-soon-text">Coming Soon</div> */}
                </div>
                <Footer />
            </div>
        </>
    );
};

export default ComingSoon;
