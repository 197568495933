
import logo from '../../assets/img/logo.png';


import axios from 'axios';
import React, { useState, useEffect, useCallback, useRef , useContext} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { WalletContext } from '../Common/WalletContext';

import '../../assets/css/team.css';



// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Pagination from './react-pegination';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function My_downline() {
    const { walletAddress } = useContext(WalletContext);

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { level } = useParams();
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const loadingRef = useRef(false);
  
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  
    const fetchData = useCallback(async (currentPage) => {
      if (loadingRef.current || !hasMore) return;
  
      loadingRef.current = true;
      setLoading(true);
      try {
        const response = await axios.get(`${apiBaseUrl}/downline/${walletAddress}/${level}`, {
          params: { page: currentPage }
        });
  
        if (response.data && response.data.length > 0) {
          setData(prevData => {
            const newData = response.data.filter(newItem => 
              !prevData.some(existingItem => existingItem.user_id === newItem.user_id)
            );
            return [...prevData, ...newData];
          });
          setPage(prevPage => prevPage + 1);
          setHasMore(response.data.length > 0);
        } else if (currentPage === 1) {
          setData([]);
          setHasMore(false);
        } else {
          setHasMore(false);
        }
      } catch (error) {
        setError(error);
        toast.error('Error fetching data');
      } finally {
        setLoading(false);
        loadingRef.current = false;
      }
    }, [apiBaseUrl, level, walletAddress]);
  
    useEffect(() => {
      setData([]);
      setPage(1);
      setHasMore(true);
      fetchData(1);
    }, [level, fetchData]);
  
    useEffect(() => {
      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 100
        ) {
          if (hasMore && !loadingRef.current) {
            fetchData(page);
          }
        }
      };
  
      const debouncedHandleScroll = debounce(handleScroll, 200);
  
      window.addEventListener('scroll', debouncedHandleScroll);
      return () => window.removeEventListener('scroll', debouncedHandleScroll);
    }, [page, hasMore, fetchData]);
  
    function debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }
  
    const formatDate = (dateString) => {
      const months = [
        'Jan', 'Feb', 'March', 'April', 'May', 'June',
        'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
      ];
  
      const date = new Date(dateString);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();
  
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
  
      return `${day} ${month} ${year} | ${hours}:${minutes}:${seconds}`;
    };
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }


  return (
    <body>
      <div>
        <Header />

        <section style={{marginTop:'65px'}}>
          <div>
            <div className="team-body-content">
              <center>
                <h3 className='my-3 network_heading'>Level {level} Network</h3>
              </center>
              {data ? (
                <section className="mt-2">
                  {data.map((user, index) => (
                    <div key={index} className="col-12">
                      <div className="team-transaction-box shadow">
                        <a href="#" style={{ textDecoration: 'none' }}>
                          <div>
                            <div
                              style={{
                                color: 'black',
                                width: '100px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {user.first_name}
                            </div>

                            <div className="team-transaction-name-2 mb-2">
                              {user.user_id}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span className="report-date" style={{fontSize:'10px'}}>{formatDate(user.joining_date)}</span>
                              <br />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <h6 className='' style={{color:'#0d6efd'}}>
                                ${user.user_business}

                              </h6>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  ))}
                </section>
              ) : (
                <div style={{ textAlign: 'center' }}>No Team data available</div>
              )}
            </div>
          </div>
        </section>
        <ToastContainer />
        <Footer />
      </div>
    </body>
  );
}

export default My_downline;
