import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import LevelIncomeImg from '../../assets/img/level_income_img.png';
import { WalletContext } from '../Common/WalletContext';

import '../../assets/css/Level.css'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Level_show() {
  const navigate = useNavigate();
  const { walletAddress } = useContext(WalletContext);

  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [levelSums, setLevelSums] = useState({});
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const checkWalletAndFetchData = useCallback(async (walletAddress) => {
    if (!walletAddress) {
        return;
    }

    try {
        const response = await axios.get(`${apiBaseUrl}/getuser/${walletAddress}`);
        const userData = response.data;
        const userID = userData?.id;

        if (!userID) {
            toast.error('User not found.');
            navigate('/register');
        } else {
            localStorage.setItem('user_ID', userID);
        }
    } catch (error) {
        toast.error(error.message);

    }
}, [apiBaseUrl, navigate]);


  useEffect(() => {
    checkWalletAndFetchData(walletAddress);
    fetchData();
  }, [walletAddress]);

  const fetchData = async () => {
    try {
      setLoading(true); // Start loading
  
      // const id = localStorage.getItem('walletAddress');
      
      // Check if ID exists, handle early exit if it's null/undefined
      // if (!id) {
      //   navigate('/register');
      //   toast.error('No wallet address found, please register.');
      //   return;
      // }
  
      const response = await axios.get(`${apiBaseUrl}/userteamapi/${walletAddress}`);
      if (response.data != null) {
        // Safely extract my_downline
        const { my_downline } = response.data;
  
        // Set state with data
        setData(my_downline);
        setData1(response.data);
        
        // Call your function to calculate level sums
        calculateLevelSums(my_downline);
      } else {
        navigate('/register');
        toast.error('User not registered or no downline found.');
      }
  
    } catch (error) {
      setError(error);
      // toast.error('Failed to fetch user data. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
  

  const calculateLevelSums = (downlineData) => {
    const sums = {};
    downlineData.forEach((member) => {
      const { level, user_business } = member;
      // Ensure user_business is parsed as an integer
      sums[level] = (sums[level] || 0) + parseInt(user_business, 10);
    });
    setLevelSums(sums);
  };


  const handleLevelClick = (level) => {
    navigate(`/my_downline/${level}`)
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div style={{ overflow: 'hidden' }}>
      <Header />

      <section style={{marginTop:'65px'}}>
        <div>
          <div className="team-body-content">
            <center>
              <h3 className='my-2 network_heading'>Level Network</h3>
            </center>
            <section>
              <div className='total_business'>
                <div>
                  <img src={LevelIncomeImg} alt="Direct Income" className="portfolio-icon" />
                </div>
                <div>
                  <div style={{color:'white'}}>Total Team Staking</div>
                  <h6 style={{color:'white'}}>${data1.user.my_business}</h6>
                </div>
              </div>
            </section>
            <section className="mt-2">
              <div className="row">
                {[...Array(21).keys()].map((level) => (
                  <div
                    onClick={() => handleLevelClick(level + 1)}
                    style={{ textDecoration: 'none' }}
                  >
                    <div key={level + 1}>
                      <div className="team-transaction-box shadow d-flex justify-content-between align-items-center">

                        <div style={{ color: 'black' }}>
                          Level {level + 1}
                        </div>

                        <div style={{ color: 'black' }}>
                           ${levelSums[level + 1] || 0}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      </section>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Level_show;